.reactMarkDown{

}

.reactMarkDown p{
    margin: 0;
    padding: 0;
    color: #4A5060;
    font-size: .95em;
    line-height: 1.4em;
}

.reactMarkDown h3{
    color: #EC8E1F;
    line-height: 1.1rem;
    font-weight: 100;
    margin: 14px 0;
    padding: 0;
}